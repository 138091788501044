const initAnchorLink = () => {
  document.addEventListener('click', (e) => {
    const anchorLink = e.target.closest('[data-anchor-link]');

    if (anchorLink) {
      e.preventDefault();

      const id = anchorLink.getAttribute('href');
      const targetBlock = document.querySelector(id);

      if (targetBlock) {
        const offset = (window.innerHeight - targetBlock.scrollHeight) / 2;
        const offsetScroll = offset < 0 ? 0 : offset;
        const targetBlockPosition = targetBlock.getBoundingClientRect().top;
        const y = targetBlockPosition + window.pageYOffset - offsetScroll;

        window.scrollTo({
          top: y,
          behavior: 'smooth',
        });
      }
    }
  });
};

export {initAnchorLink};
