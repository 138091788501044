const initChannelsAnim = () => {
  const channels = document.querySelector('[data-channels]');

  if (!channels) {
    return;
  }

  const animate = channels.querySelector('animate');

  channels.addEventListener('sal:in', (e) => {
    animate.beginElement();
  });
};

export {initChannelsAnim};
